import { call, put, takeEvery } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
import {
  GET_GROUPS,
  SAVE_GROUPS,
  GET_SINGLE_GROUP,
  UPDATE_SINGLE_GROUP,
} from "./actionType";
import {
  groupSuccess,
  saveGroupSucces,
  saveGroupError,
  getSingleGroupSucces,
  updateGroupsSucces,
} from "./action";
import { apiError } from "../auth/login/actions";

const api = new APIClient();

function* GroupsApi() {
  try {
    const getGroups = (data) => api.get("/groups", data, true);
    const response = yield call(getGroups);
    yield put(groupSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* saveNewGroupApi(action) {
  const { payload } = action;
  try {
    const saveGroup = (data) => api.create("/groups", data, true);
    const response = yield call(saveGroup, payload);
    const { error = false } = response;
    !error
      ? yield put(saveGroupSucces(response))
      : yield put(saveGroupError(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* singleGroupApi(action) {
  const { payload } = action;
  try {
    const getTariffsSingle = (data) => api.get(`groups/${payload}`, data, true);
    const response = yield call(getTariffsSingle);
    yield put(getSingleGroupSucces(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateGroupApi(action) {
  const { payload, id } = action;
  try {
    const updateGroup = (data) => api.put(`groups/${id}`, data, true);
    const response = yield call(updateGroup, payload);
    yield put(updateGroupsSucces(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* GroupsSaga() {
  yield takeEvery(GET_GROUPS, GroupsApi);
  yield takeEvery(SAVE_GROUPS, saveNewGroupApi);
  yield takeEvery(GET_SINGLE_GROUP, singleGroupApi);
  yield takeEvery(UPDATE_SINGLE_GROUP, updateGroupApi);
}

export default GroupsSaga;
