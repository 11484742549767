import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
// Login Redux States
import {
  GET_TARIFFS,
  SAVE_TARIFF,
  GET_SINGLE_TARIFF,
  UPDATE_SINGLE_TARIFF,
} from "./actionType";
import {
  tariffSuccess,
  tariffListingError,
  saveTariffSucces,
  saveTariffError,
  getSingleTariffSucces,
  getSingleTariffError,
  updateTariffSucces,
  updateTariffError,
} from "./action";
import { apiError } from "../auth/login/actions";

const api = new APIClient();

function* TariffsApi() {
  try {
    const getTariffs = (data) => api.get("/tariffs", data, true);
    const response = yield call(getTariffs);
    yield put(tariffSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* saveNewTariffApi(action) {
  const { payload } = action;
  try {
    const saveCustomer = (data) => api.create("/tariffs", data, true);
    const response = yield call(saveCustomer, payload);
    const { error = false } = response;
    !error
      ? yield put(saveTariffSucces(response))
      : yield put(saveTariffError(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* singleTariffApi(action) {
  const { payload } = action;
  try {
    const getTariffsSingle = (data) =>
      api.get(`tariffs/${payload}`, data, true);
    const response = yield call(getTariffsSingle);
    yield put(getSingleTariffSucces(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateTariffApi(action) {
  const { payload, id } = action;
  try {
    const updateTariffs = (data) => api.put(`tariffs/${id}`, data, true);
    const response = yield call(updateTariffs, payload);
    yield put(updateTariffSucces(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* TariffsSaga() {
  yield takeEvery(GET_TARIFFS, TariffsApi);
  yield takeEvery(SAVE_TARIFF, saveNewTariffApi);
  yield takeEvery(GET_SINGLE_TARIFF, singleTariffApi);
  yield takeEvery(UPDATE_SINGLE_TARIFF, updateTariffApi);
}

export default TariffsSaga;
