import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Input,
  Form,
  FormFeedback,
  Spinner,
  Button,
  Alert,
  Modal,
  ModalBody,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  saveTariff,
  resetTariffSavedData,
  getSingleTariff,
  updateTariff,
} from "../../store/actions";
import { createSelector } from "reselect";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

const TariffEntryForm = () => {
  document.title = "Tariffs Entry Form | Arun Chit Funds, Oddanchatram";
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const {
    state: { mode = "NEW", editID = 0 },
  } = location;
  const [dynamicMonthsField, setDynamicMonthsField] = useState(0);
  const selectTariffssListState = (state) => state.Tariffs;
  const selectTariffsProperties = createSelector(
    selectTariffssListState,
    (tariffs) => ({
      savedNewTariffData: tariffs?.saveNewTariff?.data || [],
      saveLoading: tariffs?.saveNewTariff?.loading,
      saveError: tariffs?.saveNewTariff?.error,
      saveSuccess: tariffs?.saveNewTariff?.success,
      singleTariffData: tariffs?.singleTariff?.data || [],
      singlTariffLoading: tariffs?.singleTariff?.loading,
      singleTariffError: tariffs?.singleTariff?.error,
      singleTariffSuccess: tariffs?.singleTariff?.success,
      updateTariffData: tariffs?.updateTariff?.data || [],
      updateTariffLoading: tariffs?.updateTariff?.loading,
      updateTariffError: tariffs?.updateTariff?.error,
      updateTariffSuccess: tariffs?.updateTariff?.success,
    })
  );
  const {
    savedNewTariffData,
    saveLoading,
    saveError,
    saveSuccess,
    singleTariffData = [],
    singlTariffLoading,
    singleTariffError,
    singleTariffSuccess,
    updateTariffData,
    updateTariffSuccess,
    updateTariffLoading,
    updateTariffError,
  } = useSelector(selectTariffsProperties);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name:
        mode == "EDIT" && singleTariffData.length === 1
          ? singleTariffData[0]?.name
          : "",
      months:
        mode == "EDIT" && singleTariffData.length === 1
          ? singleTariffData[0]?.months
          : "",
      amount:
        mode == "EDIT" && singleTariffData.length === 1
          ? singleTariffData[0]?.chit_amount
          : "",
      threshold:
        mode == "EDIT" && singleTariffData.length === 1
          ? singleTariffData[0]?.threshold.split(",")
          : [],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(2, "Name must be at least 2 characters")
        .max(50, "Name can be at most 50 characters")
        .matches(
          /^[a-zA-Z0-9()-\s]*$/,
          "Tariff name can only contain letters and spaces"
        ),
      months: Yup.number()
        .positive("Tariff month must be positive")
        .required("Tariff month is required"),
      amount: Yup.number()
        .typeError("Amount must be a number")
        .positive("Amount must be positive")
        .required("Amount is required"),
      threshold: Yup.array()
        .test(
          "threshold_check_empty",
          "Amount for all months are required.",
          (value) => {
            const thresholdValues = value.filter(
              (el) => el != "" && el !== undefined
            );
            return thresholdValues?.length !== 0;
          }
        )
        .test(
          "threshold_check_all",
          "Incomplete monthly amounts. Ensure you specify amounts for every month.",
          (value) => {
            const thresholdValues = value.filter(
              (el) => el != "" && el !== undefined
            );
            return parseInt(formik.values.months) === thresholdValues?.length;
          }
        )
        .test(
          "threshold_check_values_are_incrementing",
          "The amount set for some months is not incrementing from the previous month.",
          (value) => {
            for (let i = 2; i < value.length; i++) {
              if (parseInt(value[i]) <= parseInt(value[i - 1])) {
                return false;
              }
            }
            return true;
          }
        )
        .test(
          "threshold_check_value_for_last_month",
          "The amount set for last month is greater than the chit amount.",
          (value) => {
            const [lastValue] = value.slice(-1);
            return formik.values.amount >= lastValue;
          }
        ),
    }),
    onSubmit: (values) => {
      const additionalInfo = { addedBy: 1 };
      mode == "EDIT"
        ? dispatch(updateTariff({ ...values, ...additionalInfo }, editID))
        : dispatch(saveTariff({ ...values, ...additionalInfo }));
    },
  });

  useEffect(() => {
    /^-?\d+$/.test(formik.values.months) &&
      setDynamicMonthsField(formik.values.months);
  }, [formik.values.months]);

  useEffect(() => {
    dispatch(resetTariffSavedData());
  }, []);

  useEffect(() => {
    mode === "EDIT" && dispatch(getSingleTariff(editID));
  }, [mode, editID]);

  useEffect(() => {
    updateTariffSuccess &&
      setTimeout(() => {
        history("/tariffs", { state: { refresh: true } });
      }, 3000);
  }, [updateTariffSuccess]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tariff Form" pageTitle="Home" />
          <Row className="justify-content-center">
            <Col>
              <Card>
                {singlTariffLoading ? (
                  <CardBody>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                    </p>
                  </CardBody>
                ) : (
                  <CardBody>
                    {saveError && (
                      <Alert color="danger">
                        {`${savedNewTariffData?.error_message?.toString()}`}
                      </Alert>
                    )}
                    {updateTariffSuccess && (
                      <Alert color="success">
                        {`${updateTariffData?.message}`} The page will redirect
                        back to the list page shortly.
                      </Alert>
                    )}
                    <Form
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                      action="#"
                    >
                      <div className="row g-3">
                        <Col xxl={4}>
                          <div>
                            <label htmlFor="name" className="form-label">
                              Name <span className="text-danger">*</span>
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Enter Tariff Name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                formik.touched.name && formik.errors.name
                              }
                              value={formik.values.name}
                            />

                            {formik.touched.name && formik.errors.name ? (
                              <FormFeedback type="invalid">
                                {formik.errors.name}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={4}>
                          <div>
                            <label htmlFor="months" className="form-label">
                              Months <span className="text-danger">*</span>
                            </label>
                            <Input
                              type="text"
                              className="form-control"
                              id="months"
                              name="months"
                              placeholder="Enter Total Chit Months"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              invalid={
                                formik.touched.months && formik.errors.months
                              }
                              value={formik.values.months}
                            />

                            {formik.touched.months && formik.errors.months ? (
                              <FormFeedback type="invalid">
                                {formik.errors.months}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                        <Col xxl={4}>
                          <div>
                            <label htmlFor="amount" className="form-label">
                              Chit Amount <span className="text-danger">*</span>
                            </label>
                            <div className="input-group">
                              <span className="input-group-text">&#8377;</span>
                              <Input
                                type="text"
                                className="form-control"
                                id="amount"
                                name="amount"
                                placeholder="Enter Chit Amount"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                invalid={
                                  formik.touched.amount && formik.errors.amount
                                }
                                value={formik.values.amount}
                              />
                            </div>
                            {formik.touched.amount && formik.errors.amount ? (
                              <FormFeedback type="invalid">
                                {formik.errors.amount}
                              </FormFeedback>
                            ) : null}
                          </div>
                        </Col>
                      </div>
                      {dynamicMonthsField > 0 && (
                        <Row className="g-3 mt-2">
                          <Col xxl={12}>
                            <h6>{`Monthly Fixed Amount for ${dynamicMonthsField} Months`}</h6>
                            {formik.errors.threshold ? (
                              <div className="text-danger">
                                {formik.errors.threshold}
                              </div>
                            ) : null}
                          </Col>
                          {Array.from(
                            Array(Number(dynamicMonthsField)).keys()
                          ).map((e, i) => (
                            <Col xxl={2} key={i}>
                              <label htmlFor="amount" className="form-label">
                                Month {i + 1}{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="input-group">
                                <span className="input-group-text">
                                  &#8377;
                                </span>
                                <Input
                                  type="number"
                                  className="form-control"
                                  id={`threshold${i}`}
                                  name={`threshold[${i}]`}
                                  value={formik.values.threshold[i]}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  placeholder={`Amount for month ${i + 1}`}
                                  min={1}
                                />
                              </div>
                            </Col>
                          ))}
                        </Row>
                      )}
                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none btn-label previestab"
                          onClick={() =>
                            history("/tariffs", { state: { refresh: true } })
                          }
                        >
                          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                          Back to Tariff Listing
                        </button>
                        <Button
                          color="primary"
                          type="submit"
                          className="btn-label right ms-auto nexttab nexttab"
                          disabled={
                            (saveLoading && !saveError) ||
                            (updateTariffLoading && !updateTariffError)
                          }
                        >
                          {saveError ||
                          updateTariffError ? null : saveLoading ||
                            updateTariffLoading ? (
                            <Spinner size="sm" className="me-2">
                              {" "}
                              Loading...{" "}
                            </Spinner>
                          ) : null}
                          {mode !== "EDIT" ? "Save" : "Update"}
                          <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                        </Button>
                      </div>
                    </Form>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
          {saveSuccess && (
            <Modal
              isOpen={true}
              backdrop={"static"}
              id="staticBackdrop"
              centered
            >
              <ModalBody className="text-center p-2">
                <div className="mt-0">
                  <i className="mdi mdi-sticker-check text-success-emphasis modal-icon"></i>
                  <p className="text-muted mb-4 text-success-emphasis">{`${savedNewTariffData?.message}`}</p>
                  <div className="hstack gap-2 justify-content-center">
                    <Button
                      color="primary"
                      onClick={() =>
                        history("/tariffs", { state: { refresh: true } })
                      }
                    >
                      Close
                    </Button>
                  </div>
                </div>
              </ModalBody>
            </Modal>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TariffEntryForm;
