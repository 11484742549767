import moment from "moment";
import { GROUP_START_MONTHS } from "./constants";

export function formatIndianRupee(number = 0) {
  return number.toLocaleString("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 2,
  });
}

export function monthArray(
  pastMonthRequired = true,
  currentMonthRequired = true,
  futureMonthRequired = true
) {
  const currentMonth = moment();
  const monthsArray = [];
  if (pastMonthRequired) {
    for (let i = GROUP_START_MONTHS; i >= 1; i--) {
      const lastMonth = moment().subtract(i, "months");
      monthsArray.push({
        label: lastMonth.format("MMMM YYYY"),
        value: lastMonth.format("MMMM YYYY"),
      });
    }
  }
  currentMonthRequired &&
    monthsArray.push({
      label: currentMonth.format("MMMM YYYY"),
      value: currentMonth.format("MMMM YYYY"),
    });
  if (futureMonthRequired) {
    for (let i = 1; i <= GROUP_START_MONTHS; i++) {
      const nextMonth = moment().add(i, "months");
      monthsArray.push({
        label: nextMonth.format("MMMM YYYY"),
        value: nextMonth.format("MMMM YYYY"),
      });
    }
  }
  return monthsArray;
}

export function greeting() {
  const hour = moment().hour();
  if (hour > 16) {
    return "Good evening";
  }
  if (hour > 11) {
    return "Good afternoon";
  }
  return "Good morning";
}

export function getNonUniqueObjects(array1 = [], array2 = []) {
  const nonUniqueObjects = [];
  for (const obj of array1) {
    if (array2.some((item) => JSON.stringify(item) === JSON.stringify(obj))) {
      nonUniqueObjects.push(obj);
    }
  }
  return nonUniqueObjects;
}

export function groupBy(jsonArray, key) {
  return jsonArray.reduce((result, item) => {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
}
