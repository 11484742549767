import React, { useState, useMemo, useEffect } from "react";
import { Col, Container, Row, CardBody, Card, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Grid from "../../Components/Grid/Grid";
import { getCustomers } from "../../store/actions";
import { createSelector } from "reselect";
import EntryForm from "./EntryForm";
import ReactDOM from "react-dom";

const Customers = () => {
  document.title = "Customer Management | Arun Chit Funds, Oddanchatram";
  const [openEntryForm, setOpenEntryForm] = useState(false);
  const [mode, setMode] = useState("NEW");
  const [idToEdit, setIdToEdit] = useState("");
  const dispatch = useDispatch();
  const selectCustomersListState = (state) => state.Customers;
  const selectCustomersProperties = createSelector(
    selectCustomersListState,
    (customers) => ({
      gridData: customers?.data,
      loading: customers?.loading,
      error: customers?.error,
      success: customers?.success,
    })
  );
  const { gridData, loading, error, success } = useSelector(
    selectCustomersProperties
  );
  const gridColumns = useMemo(
    () => [
      {
        data: "id",
        title: "Cutomer ID",
      },
      {
        data: "name",
        title: "Customer Name",
      },
      {
        data: "phone",
        title: "Customer Phone",
      },
      {
        data: "id",
        title: "Action",
      },
    ],
    []
  );
  const buttons = (
    <>
      <Button
        color="primary"
        className="bg-gradient"
        disabled={false}
        onClick={() => {
          setOpenEntryForm(true);
          setIdToEdit(0);
          setMode("NEW");
        }}
      >
        Add New Customer
      </Button>
    </>
  );

  const editOnClick = (id) => {
    setIdToEdit(id);
    setMode("EDIT");
    setOpenEntryForm(true);
  };

  const columnDefs = [
    {
      targets: 3,
      createdCell: (td, cellData, row, rowData, col) =>
        ReactDOM.render(
          <>
            <Button
              color="primary"
              className="bg-gradient btn-sm"
              onClick={() => {
                editOnClick(cellData);
              }}
            >
              {" "}
              Edit{" "}
            </Button>
          </>,
          td
        ),
    },
  ];

  useEffect(() => {
    !success && dispatch(getCustomers());
  }, [success]);

  return (
    <React.Fragment>
      {openEntryForm && (
        <EntryForm
          isOpen={openEntryForm}
          mode={mode}
          idToEdit={idToEdit}
          onModalToggleCB={(status, retrigger) => {
            setOpenEntryForm(status);
            retrigger && dispatch(getCustomers());
          }}
        />
      )}
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Customer Management" pageTitle="Dashboard" />
          <Row className="justify-content-center">
            <Col>
              <Card>
                <CardBody>
                  <Grid
                    columns={gridColumns}
                    data={gridData || []}
                    columnDefs={columnDefs}
                    buttons={buttons}
                    isLoading={loading}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Customers;
