import React from "react";
import { Navigate } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Customers from "../pages/Customers";
import Tariffs from "../pages/Tariffs";
import TariffEntryForm from "../pages/Tariffs/EntryForm";
import Groups from "../pages/Groups";
import GroupEntryForm from "../pages/Groups/EntryForm";
import PrintSheet from "../pages/Print";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/index", component: <Dashboard /> },
  { path: "/customers", component: <Customers /> },
  { path: "/tariffs", component: <Tariffs /> },
  { path: "/tariffs/entry-form", component: <TariffEntryForm /> },
  { path: "/groups", component: <Groups /> },
  { path: "/groups/entry-form", component: <GroupEntryForm /> },
  { path: "/print/print-sheet", component: <PrintSheet /> },

  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },
  { path: "*", component: <Navigate to="/dashboard" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
];

export { authProtectedRoutes, publicRoutes };
