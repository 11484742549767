import React, { useState, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Label,
  Input,
  Button,
  Form,
  FormFeedback,
  Badge,
  Alert,
  Modal,
  ModalBody,
  Spinner,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { monthArray } from "../../helpers";
import { createSelector } from "reselect";
import {
  getTariffs,
  getCustomers,
  saveGroup,
  resetGroupSavedData,
  getSingleGroup,
  updateGroups,
  resetUpdateGroupsData,
} from "../../store/actions";
import moment from "moment";
import ViewTariff from "../Tariffs/view";
import Select from "react-select";
import { DEAFULT_ALLOWED_MEMBERS } from "../../helpers/constants";
import { useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useFormik } from "formik";

const GroupEntryForm = () => {
  document.title = "Groups Entry Form | Arun Chit Funds, Oddanchatram";
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const [viewTariffData, setViewTariffData] = useState({});
  const [showViewModal, setShowViewModal] = useState(false);
  const [showViewTariffDataLink, setShowViewTariffDataLink] = useState(false);
  const [masterCustomers, setMasterCustomers] = useState([]);
  const [masterTariffs, setMasterTariffs] = useState([]);

  const {
    state: { mode = "NEW", editID = 0 },
  } = location;

  const selectTariffsListState = (state) => state.Tariffs;
  const selectCustomersListState = (state) => state.Customers;
  const selectGroupsState = (state) => state.Groups;

  const selectTariffssProperties = createSelector(
    selectTariffsListState,
    (tariffs) => ({
      tariffData: tariffs?.data,
      tariffLoading: tariffs?.loading,
      tarrifError: tariffs?.error,
      tariffSuccess: tariffs?.success,
    })
  );
  const selectCustomersProperties = createSelector(
    selectCustomersListState,
    (customers) => ({
      customeresData: customers?.data,
      customersLoading: customers?.loading,
      customersError: customers?.error,
      customerSuccess: customers?.success,
    })
  );
  const selectGroupsProperties = createSelector(
    selectGroupsState,
    (groups) => ({
      saveGroupsData: groups?.saveNewGroup?.data,
      saveGroupsLoading: groups?.saveNewGroup?.loading,
      saveGroupsError: groups?.saveNewGroup?.error,
      saveGroupsSuccess: groups?.saveNewGroup?.success,
      singleGroupsData: groups?.singleGroup?.data,
      singleGroupsLoading: groups?.singleGroup?.loading,
      singleGroupsError: groups?.singleGroup?.error,
      singleGroupsSuccess: groups?.singleGroup?.success,
      updateGroupsData: groups?.updateGroup?.data,
      updateGroupsLoading: groups?.updateGroup?.loading,
      updateGroupsError: groups?.updateGroup?.error,
      updateGroupsSuccess: groups?.updateGroup?.success,
    })
  );
  const { tariffData, tariffLoading, tariffSuccess } = useSelector(
    selectTariffssProperties
  );
  const { customeresData, customersLoading, customerSuccess } = useSelector(
    selectCustomersProperties
  );
  const {
    saveGroupsData,
    saveGroupsLoading,
    saveGroupsError,
    saveGroupsSuccess,
    singleGroupsData,
    singleGroupsLoading,
    singleGroupsError,
    singleGroupsSuccess,
    updateGroupsData,
    updateGroupsLoading,
    updateGroupsError,
    updateGroupsSuccess,
  } = useSelector(selectGroupsProperties);

  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: mode === "EDIT" || mode === "VIEW" ? singleGroupsData[0]?.name : "",
      months:
        mode === "EDIT" || mode === "VIEW"
          ? {
              label: singleGroupsData[0]?.started_month,
              value: singleGroupsData[0]?.started_month,
            }
          : {
              label: moment().format("MMMM YYYY"),
              value: moment().format("MMMM YYYY"),
            },
      tariff:
        mode === "EDIT" || mode === "VIEW"
          ? {
              label: singleGroupsData[0]?.tarrifs_name,
              value: singleGroupsData[0]?.tarrifs_id,
            }
          : "",
      members:
        mode === "EDIT" || mode === "VIEW"
          ? singleGroupsData[0]?.max_allowed_members
          : DEAFULT_ALLOWED_MEMBERS,
      customers: [],
      customersCount: [],
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Grroup name is required")
        .min(2, "Group name must be at least 2 characters")
        .max(50, "Group name can be at most 50 characters")
        .matches(
          /^[a-zA-Z0-9()-\s]*$/,
          "Group name should be only alphanumeric characters, hyphens, parentheses, and spaces are allowed."
        ),
      months: Yup.object().required("Tariff month is required"),
      tariff: Yup.object().required("Tariff is required"),
      members: Yup.number()
        .typeError("Total members count must be a number")
        .positive("Total members count must be positive")
        .required("Total members count is required"),
      customers: Yup.array().min(1, "Customer is required"),
      customersCount: Yup.array()
        .test(
          "customersCount_check_empty",
          "Customers listed below should have a value of at least 1. If they are not required, please remove them using the dropdown above.",
          (value) => {
            const customersValues = value.filter(
              (el) =>
                el.value != "" && el.value !== undefined && !isNaN(el.value)
            );
            return customersValues?.length === formik.values.customers?.length;
          }
        )
        .test(
          "customersCount_check_value_exceeds",
          "The customer count exceeds the allowed number of members.",
          (value) => {
            return (
              formik.values.customersCount.reduce((a, b) => {
                const value = isNaN(b.value) ? 0 : b.value;
                return a + value;
              }, 0) <= parseInt(formik.values.members)
            );
          }
        ),
    }),
    onSubmit: (payload) => {
      let dataToPass = { ...payload };
      dataToPass.months = dataToPass.months.value;
      dataToPass.tariff = dataToPass.tariff.value;
      dataToPass.addedBy = 1;
      delete dataToPass.customers;
      mode !== "EDIT" && dispatch(saveGroup(dataToPass));
      mode === "EDIT" && dispatch(updateGroups(dataToPass, editID));
    },
  });

  const tariffOnChange = ({ value }) => {
    if (value == -1) {
      setShowViewTariffDataLink(false);
      setViewTariffData({});
      return;
    }
    const getTariffData = tariffData.filter((tariff) => {
      return tariff.id === parseInt(value);
    });
    setViewTariffData(getTariffData[0]);
    setShowViewTariffDataLink(true);
  };

  useEffect(() => {
    !tariffSuccess && dispatch(getTariffs());
  }, [tariffSuccess]);

  useEffect(() => {
    !customerSuccess && dispatch(getCustomers());
  }, [customerSuccess]);

  useEffect(() => {
    customeresData.length > 0 &&
      setMasterCustomers(
        Object.entries(customeresData).map(([i, info]) => ({
          value: info?.id,
          label: `${info?.name}`,
        }))
      );
  }, [customeresData]);

  useEffect(() => {
    if (singleGroupsData.length === 1 && (mode === "EDIT" || mode === "VIEW")) {
      formik.setFieldValue(
        "customers",
        populateCustomerDropDownWhileEdit(singleGroupsData[0].customers)
      );
      formik.setFieldValue(
        "customersCount",
        populateCustomerDropDownWhileEdit(singleGroupsData[0].customers, true)
      );
      tariffOnChange({
        label: singleGroupsData[0]?.tarrifs_name,
        value: singleGroupsData[0]?.tarrifs_id,
      });
      setTimeout(() => formik.setErrors({}), 1);
    }
  }, [singleGroupsData, mode]);

  const populateCustomerDropDownWhileEdit = (data, isForCount = false) => {
    return data.map((datum) => {
      return {
        value: isForCount ? datum.count : datum.id,
        label: isForCount ? datum.id : datum.name,
        id: isForCount ? datum.id : datum.id,
      };
    });
  };

  useEffect(() => {
    tariffData.length > 0 &&
      setMasterTariffs(
        Object.entries(tariffData).map(([i, info]) => ({
          value: info?.id,
          label: info?.name,
        }))
      );
  }, [tariffData, mode]);

  useEffect(() => {
    dispatch(resetGroupSavedData());
    dispatch(resetUpdateGroupsData());
  }, []);

  useEffect(() => {
    (mode === "EDIT" || mode === "VIEW") && dispatch(getSingleGroup(editID));
  }, [mode, editID]);

  useEffect(() => {
    updateGroupsSuccess &&
      setTimeout(() => {
        history("/groups", { state: { refresh: true } });
      }, 3000);
  }, [updateGroupsSuccess]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Group Form" pageTitle="Home" />
          <Row className="justify-content-center">
            <Col>
              <Card>
                {singleGroupsLoading ? (
                  <CardBody>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                    </p>
                  </CardBody>
                ) : (
                  <CardBody>
                    {saveGroupsError && (
                      <Alert color="danger">
                        {`${saveGroupsData?.error_message?.toString()}`}
                      </Alert>
                    )}
                    {updateGroupsSuccess && (
                      <Alert color="success">
                        {`${updateGroupsData?.message}`} The page will redirect
                        back to the list page shortly.
                      </Alert>
                    )}
                    <Form
                      autoComplete="off"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                      action="#"
                    >
                      <div className="row g-3">
                        <Col xxl={3}>
                          <Label htmlFor="name" className="form-label">
                            Name <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Enter Group Name"
                            readOnly={!(mode !== "EDIT")}
                            disabled={mode === "VIEW"}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={formik.touched.name && formik.errors.name}
                            value={formik.values.name}
                          />
                          {formik.touched.name && formik.errors.name ? (
                            <FormFeedback type="invalid">
                              {formik.errors.name}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col xxl={3}>
                          <Label htmlFor="months" className="form-label">
                            Group Start Month{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Select
                            onChange={(selectedOption, e) => {
                              formik.setFieldValue("months", selectedOption);
                            }}
                            options={monthArray(true, true, false)}
                            name="months"
                            id="months"
                            isDisabled={mode === "VIEW"}
                            value={formik.values.months}
                            invalid={formik.errors.months != undefined}
                            isSearchable={true}
                          ></Select>
                          {formik.errors.months ? (
                            <FormFeedback type="invalid" className="d-block">
                              {formik.errors.months}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col xxl={3}>
                          <Label htmlFor="name" className="form-label">
                            Tariff <span className="text-danger">*</span>{" "}
                            {showViewTariffDataLink && (
                              <>
                                <button
                                  type="button"
                                  onClick={() => setShowViewModal(true)}
                                  className="btn link-info link-offset-2 text-decoration-underline link-underline-opacity-25 link-underline-opacity-100-hover p-0"
                                >
                                  See Tariff Details
                                </button>
                              </>
                            )}
                            {showViewModal && (
                              <ViewTariff
                                onViewModalClose={() => {
                                  setShowViewModal(false);
                                }}
                                dataToView={viewTariffData}
                              />
                            )}
                          </Label>
                          <Select
                            onChange={(selectedOption, e) => {
                              formik.setFieldValue("tariff", selectedOption);
                              tariffOnChange(selectedOption);
                            }}
                            options={masterTariffs}
                            name="tariff"
                            id="tariff"
                            isLoading={tariffLoading}
                            isDisabled={mode === "VIEW"}
                            loadingMessage={() =>
                              "Fetching tariffs list, please wait..."
                            }
                            value={formik.values.tariff}
                            invalid={formik.errors.tariff != undefined}
                          ></Select>
                          {formik.errors.tariff ? (
                            <FormFeedback type="invalid" className="d-block">
                              {formik.errors.tariff}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col xxl={3}>
                          <Label htmlFor="name" className="form-label">
                            Allowed Members{" "}
                            <span className="text-danger">*</span>
                          </Label>
                          <Input
                            type="number"
                            className="form-control"
                            id="members"
                            name="members"
                            disabled={mode === "VIEW"}
                            placeholder="Enter allowed members"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            invalid={
                              formik.touched.members && formik.errors.members
                            }
                            value={formik.values.members}
                          />
                          {formik.touched.members && formik.errors.members ? (
                            <FormFeedback type="invalid">
                              {formik.errors.members}
                            </FormFeedback>
                          ) : null}
                        </Col>
                        <Col xxl={12}>
                          <h5 className={"mt-3"}>
                            Set the total number of chits allocated to each
                            customers in this group
                          </h5>
                          <small>
                            When allocating the chit count to each customer, the
                            total count should not exceed{" "}
                            <Badge className="badge-primary fw-bold">{`${formik.values.members}`}</Badge>
                            .
                          </small>
                        </Col>
                        <Col xxl={12}>
                          <Label htmlFor="name" className="form-label">
                            Customers <span className="text-danger">*</span>
                          </Label>
                          <Select
                            isMulti
                            isDisabled={mode === "VIEW"}
                            onChange={(selectedOption) => {
                              formik.setFieldValue("customers", selectedOption);
                              const customersCount = Object.entries(
                                selectedOption
                              ).map(([i, info]) => {
                                let newInfo = info;
                                let value = 1;
                                const objIndex =
                                  formik.values.customersCount.findIndex(
                                    (obj) => obj.id == info.value
                                  );
                                if (objIndex > -1) {
                                  value =
                                    formik.values.customersCount[objIndex]
                                      ?.value || value;
                                }
                                return { id: newInfo?.value, value };
                              });
                              formik.setFieldValue(
                                "customersCount",
                                customersCount
                              );
                            }}
                            options={masterCustomers}
                            name="customers"
                            id="customers"
                            isLoading={customersLoading}
                            loadingMessage={() =>
                              "Fetching customers list, please wait..."
                            }
                            value={formik.values.customers}
                            invalid={formik.errors.customers != undefined}
                          ></Select>
                          {formik.errors.customers ? (
                            <FormFeedback type="invalid" className="d-block">
                              {formik.errors.customers}
                            </FormFeedback>
                          ) : null}
                          {formik.errors.customersCount ? (
                            <div className="text-danger mt-3">
                              {formik.errors.customersCount}
                            </div>
                          ) : null}
                        </Col>
                        {formik.values.customersCount.map((e, i) => {
                          const customerName = masterCustomers.filter((obj) => {
                            return obj?.value == e?.id;
                          })[0]?.label;
                          return (
                            <>
                              <Col xxl={2} key={i}>
                                <div className="input-group">
                                  <span className="input-group-text">
                                    {`${customerName}`}
                                    <span className="text-danger">*</span>
                                  </span>
                                  <Input
                                    type="number"
                                    className="form-control"
                                    id={`customersCount-${e?.id}`}
                                    data-customerID={`${e?.id}`}
                                    name={`customersCount[${i}]`}
                                    placeholder={`Total chit for ${e?.id}`}
                                    disabled={mode === "VIEW"}
                                    onChange={(e) => {
                                      const touchedID =
                                        e.target.getAttribute(
                                          "data-customerID"
                                        );
                                      let customersCountData =
                                        formik.values.customersCount;
                                      const objIndex =
                                        customersCountData.findIndex(
                                          (obj) => obj.id == touchedID
                                        );
                                      customersCountData[objIndex].value =
                                        parseInt(e.target.value);
                                      formik.setFieldValue(
                                        "customersCount",
                                        customersCountData
                                      );
                                    }}
                                    value={
                                      formik.values.customersCount[i].value
                                    }
                                    onBlur={formik.handleBlur}
                                    min={1}
                                  />
                                </div>
                              </Col>
                            </>
                          );
                        })}
                        <Col xxl={2}>
                          {formik.values.customersCount.length != 0 && (
                            <>
                              <Badge className="badge-primary m-0 w-xs-100 allocated">
                                Allocated{" "}
                                {formik.values.customersCount.reduce((a, b) => {
                                  const value = isNaN(b.value) ? 0 : b.value;
                                  return a + value;
                                }, 0)}{" "}
                                out of {formik.values.members}
                              </Badge>
                            </>
                          )}
                        </Col>
                      </div>
                      <div className="d-flex align-items-start gap-3 mt-4">
                        <button
                          type="button"
                          className="btn btn-link text-decoration-none btn-label previestab"
                          onClick={() =>
                            history("/groups", { state: { refresh: true } })
                          }
                        >
                          <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                          Back to Group Listing
                        </button>
                        {mode !== "VIEW" && (
                          <Button
                            color="primary"
                            type="submit"
                            className="btn-label right ms-auto nexttab nexttab"
                            disabled={
                              (saveGroupsLoading && !singleGroupsError) ||
                              (updateGroupsLoading && !updateGroupsError)
                            }
                          >
                            {singleGroupsError ||
                            updateGroupsError ? null : saveGroupsLoading ||
                              updateGroupsLoading ? (
                              <Spinner size="sm" className="me-2">
                                {" "}
                                Loading...{" "}
                              </Spinner>
                            ) : null}
                            {mode !== "EDIT" ? "Save" : "Update"}
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                          </Button>
                        )}
                      </div>
                    </Form>

                    {saveGroupsSuccess && (
                      <Modal
                        isOpen={true}
                        backdrop={"static"}
                        id="staticBackdrop"
                        centered
                      >
                        <ModalBody className="text-center p-2">
                          <div className="mt-0">
                            <i className="mdi mdi-sticker-check text-success-emphasis modal-icon"></i>
                            <p className="text-muted mb-4 text-success-emphasis">{`${saveGroupsData?.message}`}</p>
                            <div className="hstack gap-2 justify-content-center">
                              <Button
                                color="primary"
                                onClick={() =>
                                  history("/groups", {
                                    state: { refresh: true },
                                  })
                                }
                              >
                                Close
                              </Button>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>
                    )}
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default GroupEntryForm;
