import {
  GET_TARIFFS,
  GET_TARIFFS_SUCCESS,
  GET_TARIFFS_ERROR,
  SAVE_TARIFF,
  SAVE_TARIFF_SUCCESS,
  SAVE_TARIFF_ERROR,
  RESET_SAVE_TARIFF,
  GET_SINGLE_TARIFF,
  GET_SINGLE_TARIFF_SUCCESS,
  GET_SINGLE_TARIFF_ERROR,
  RESET_GET_SINGLE_TARIFF,
  UPDATE_SINGLE_TARIFF,
  UPDATE_SINGLE_TARIFF_SUCCESS,
  UPDATE_SINGLE_TARIFF_ERROR,
  RESET_UPDATE_SINGLE_TARIFF,
} from "./actionType";

const INIT_STATE = {
  data: [],
  loading: false,
  error: false,
  success: false,
  saveNewTariff: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  singleTariff: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  updateTariff: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
};

const Tariffs = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_TARIFFS:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: false,
      };
      break;
    case GET_TARIFFS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
      break;
    case GET_TARIFFS_ERROR:
      state = {
        ...state,
        data: [],
        loading: false,
        error: true,
        success: false,
      };
      break;
    case SAVE_TARIFF:
      state = {
        ...state,
        saveNewTariff: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case SAVE_TARIFF_SUCCESS:
      state = {
        ...state,
        saveNewTariff: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case SAVE_TARIFF_ERROR:
      state = {
        ...state,
        saveNewTariff: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_SAVE_TARIFF:
      state = {
        ...state,
        saveNewTariff: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    case GET_SINGLE_TARIFF:
      state = {
        ...state,
        singleTariff: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case GET_SINGLE_TARIFF_SUCCESS:
      state = {
        ...state,
        singleTariff: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case GET_SINGLE_TARIFF_ERROR:
      state = {
        ...state,
        singleTariff: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_GET_SINGLE_TARIFF:
      state = {
        ...state,
        singleTariff: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    case UPDATE_SINGLE_TARIFF:
      state = {
        ...state,
        updateTariff: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case UPDATE_SINGLE_TARIFF_SUCCESS:
      state = {
        ...state,
        updateTariff: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case UPDATE_SINGLE_TARIFF_ERROR:
      state = {
        ...state,
        updateTariff: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_UPDATE_SINGLE_TARIFF:
      state = {
        ...state,
        updateTariff: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Tariffs;
