import React from "react";
import { Col, Modal, Table, ModalBody } from "reactstrap";
import { formatIndianRupee } from "../../helpers";

const ViewTariff = ({ onViewModalClose = () => {}, dataToView }) => {
  const { id, name, months, chit_amount, threshold = "" } = dataToView;
  return (
    <React.Fragment>
      <Modal isOpen={true} centered toggle={() => onViewModalClose()}>
        <div id="myModalLabel" className="modal-title modal-header">
          <h5 className="modal-title">Tariff Details</h5>
          <button
            type="button"
            className="btn-close"
            aria-label="Close"
            onClick={() => onViewModalClose()}
          ></button>
        </div>
        <ModalBody>
          <div className="table-responsive">
            <Table className="table-bordered table-nowrap mb-0">
              <tbody>
                <tr>
                  <td>
                    <strong>Reference ID:</strong> {`${id}`}
                  </td>
                  <td>
                    <strong>Name:</strong> {`${name}`}
                  </td>
                  <td>
                    <strong>Months:</strong>{" "}
                    <span className="bg-success text-light p-2">{`${months}`}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <strong>Chit Amount:</strong>{" "}
                    <span className="bg-success text-light p-2 fw-medium">{`${formatIndianRupee(
                      chit_amount
                    )}`}</span>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <strong>Monthly Payment:</strong>
                  </td>
                </tr>
                <tr>
                  <td colSpan={3}>
                    <div className="row pt-1">
                      {
                        <>
                          {threshold.split(",").map((value, key) => (
                            <Col xxl={3} className="pb-1" key={key}>
                              <span className="fw-medium">
                                Month {key + 1}:{" "}
                              </span>
                              {formatIndianRupee(value)},
                            </Col>
                          ))}
                        </>
                      }
                    </div>
                  </td>
                </tr>
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  );
};

export default ViewTariff;
