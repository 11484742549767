import {
  GET_PRINTSHEET,
  GET_PRINTSHEET_SUCCESS,
  GET_PRINTSHEET_ERROR,
  GET_PRINTSHEET_RESET,
} from "./actionType";

const INIT_STATE = {
  data: [],
  loading: false,
  error: false,
  success: false,
};

const Print = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PRINTSHEET:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: false,
      };
      break;
    case GET_PRINTSHEET_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
      break;
    case GET_PRINTSHEET_ERROR:
      state = {
        ...state,
        data: [],
        loading: false,
        error: true,
        success: false,
      };
      break;
    case GET_PRINTSHEET_RESET:
      state = {
        ...state,
        data: [],
        loading: false,
        error: false,
        success: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Print;
