import {
  GET_GROUPS,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_ERROR,
  SAVE_GROUPS,
  SAVE_GROUPS_SUCCESS,
  SAVE_GROUPS_ERROR,
  RESET_SAVE_GROUPS,
  GET_SINGLE_GROUP,
  GET_SINGLE_GROUP_SUCCESS,
  GET_SINGLE_GROUP_ERROR,
  RESET_GET_SINGLE_GROUP,
  UPDATE_SINGLE_GROUP,
  UPDATE_SINGLE_GROUP_SUCCESS,
  UPDATE_SINGLE_GROUP_ERROR,
  RESET_UPDATE_SINGLE_GROUP,
} from "./actionType";

const INIT_STATE = {
  data: [],
  loading: false,
  error: false,
  success: false,
  saveNewGroup: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  singleGroup: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  updateGroup: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
};

const Groups = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_GROUPS:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: false,
      };
      break;
    case GET_GROUPS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
      break;
    case GET_GROUPS_ERROR:
      state = {
        ...state,
        data: [],
        loading: false,
        error: true,
        success: false,
      };
      break;
    case SAVE_GROUPS:
      state = {
        ...state,
        saveNewGroup: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case SAVE_GROUPS_SUCCESS:
      state = {
        ...state,
        saveNewGroup: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case SAVE_GROUPS_ERROR:
      state = {
        ...state,
        saveNewGroup: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_SAVE_GROUPS:
      state = {
        ...state,
        saveNewGroup: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    case GET_SINGLE_GROUP:
      state = {
        ...state,
        singleGroup: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case GET_SINGLE_GROUP_SUCCESS:
      state = {
        ...state,
        singleGroup: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case GET_SINGLE_GROUP_ERROR:
      state = {
        ...state,
        singleGroup: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_GET_SINGLE_GROUP:
      state = {
        ...state,
        singleGroup: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    case UPDATE_SINGLE_GROUP:
      state = {
        ...state,
        updateGroup: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case UPDATE_SINGLE_GROUP_SUCCESS:
      state = {
        ...state,
        updateGroup: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case UPDATE_SINGLE_GROUP_ERROR:
      state = {
        ...state,
        updateGroup: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_UPDATE_SINGLE_GROUP:
      state = {
        ...state,
        updateGroup: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Groups;
