import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { APIClient } from "../../../helpers/api_helper";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess } from "./actions";

const api = new APIClient();

function* loginUser({ payload: { user, history } }) {
  try {
    const postJwtLogin = (data) => api.create("/auth/signin", data, true);
    const response = yield call(postJwtLogin, {
      email: user.email,
      password: user.password,
    });
    const { data, status } = response;
    if (status === "success") {
      sessionStorage.setItem("authUser", JSON.stringify(data));
      yield put(loginSuccess(response));
      history("/dashboard");
    } else yield put(apiError(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* logoutUser() {
  try {
    sessionStorage.removeItem("authUser");
    yield put(logoutUserSuccess(LOGOUT_USER));
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
