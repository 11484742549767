import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";
// Login Redux States
import {
  GET_CUSTOMERS,
  SAVE_CUSTOMER,
  GET_SINGLE_CUSTOMER,
  UPDATE_SINGLE_CUSTOMER,
} from "./actionType";
import {
  customerSuccess,
  customerListingError,
  saveCustomerSucces,
  saveCustomerError,
  getSingleCustomerSucces,
  getSingleCustomerError,
  updateCustomerSucces,
  updateCustomerError,
} from "./action";
import { apiError } from "../auth/login/actions";

const api = new APIClient();

function* CustomersApi() {
  try {
    const getCustomers = (data) => api.get("/customers", data, true);
    const response = yield call(getCustomers);
    yield put(customerSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* saveNewCustomerApi(action) {
  const { payload } = action;
  try {
    const saveCustomer = (data) => api.create("/customers", data, true);
    const response = yield call(saveCustomer, payload);
    const { error = false } = response;
    !error
      ? yield put(saveCustomerSucces(response))
      : yield put(saveCustomerError(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* singleCustomerApi(action) {
  const { payload } = action;
  try {
    const getCustomers = (data) => api.get(`customers/${payload}`, data, true);
    const response = yield call(getCustomers);
    yield put(getSingleCustomerSucces(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* updateCustomerApi(action) {
  const { payload, id } = action;
  try {
    const getCustomers = (data) => api.put(`customers/${id}`, data, true);
    const response = yield call(getCustomers, payload);
    yield put(updateCustomerSucces(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* CustomersSaga() {
  yield takeEvery(GET_CUSTOMERS, CustomersApi);
  yield takeEvery(SAVE_CUSTOMER, saveNewCustomerApi);
  yield takeEvery(GET_SINGLE_CUSTOMER, singleCustomerApi);
  yield takeEvery(UPDATE_SINGLE_CUSTOMER, updateCustomerApi);
}

export default CustomersSaga;
