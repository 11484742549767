import {
  GET_TARIFFS,
  GET_TARIFFS_SUCCESS,
  GET_TARIFFS_ERROR,
  SAVE_TARIFF,
  SAVE_TARIFF_SUCCESS,
  SAVE_TARIFF_ERROR,
  RESET_SAVE_TARIFF,
  GET_SINGLE_TARIFF,
  GET_SINGLE_TARIFF_SUCCESS,
  GET_SINGLE_TARIFF_ERROR,
  RESET_GET_SINGLE_TARIFF,
  UPDATE_SINGLE_TARIFF,
  UPDATE_SINGLE_TARIFF_SUCCESS,
  UPDATE_SINGLE_TARIFF_ERROR,
  RESET_UPDATE_SINGLE_TARIFF,
} from "./actionType";

export const getTariffs = () => {
  return {
    type: GET_TARIFFS,
  };
};

export const tariffSuccess = (list) => {
  return {
    type: GET_TARIFFS_SUCCESS,
    payload: list,
  };
};

export const tariffListingError = () => {
  return {
    type: GET_TARIFFS_ERROR,
  };
};

export const saveTariff = (payload) => {
  return {
    type: SAVE_TARIFF,
    payload,
  };
};

export const saveTariffSucces = (payload) => {
  return {
    type: SAVE_TARIFF_SUCCESS,
    payload,
  };
};

export const saveTariffError = (payload) => {
  return {
    type: SAVE_TARIFF_ERROR,
    payload,
  };
};

export const resetTariffSavedData = () => {
  return {
    type: RESET_SAVE_TARIFF,
  };
};

export const getSingleTariff = (payload) => {
  return {
    type: GET_SINGLE_TARIFF,
    payload,
  };
};

export const getSingleTariffSucces = (payload) => {
  return {
    type: GET_SINGLE_TARIFF_SUCCESS,
    payload,
  };
};

export const getSingleTariffError = (payload) => {
  return {
    type: GET_SINGLE_TARIFF_ERROR,
    payload,
  };
};

export const resetSingleTariffSavedData = () => {
  return {
    type: RESET_GET_SINGLE_TARIFF,
  };
};

export const updateTariff = (payload, id) => {
  return {
    type: UPDATE_SINGLE_TARIFF,
    payload,
    id,
  };
};

export const updateTariffSucces = (payload) => {
  return {
    type: UPDATE_SINGLE_TARIFF_SUCCESS,
    payload,
  };
};

export const updateTariffError = (payload) => {
  return {
    type: UPDATE_SINGLE_TARIFF_ERROR,
    payload,
  };
};

export const resetUpdateTariffData = () => {
  return {
    type: RESET_UPDATE_SINGLE_TARIFF,
  };
};
