export const GET_GROUPS = "GET_GROUPS";
export const GET_GROUPS_SUCCESS = "GET_GROUPS_SUCCESS";
export const GET_GROUPS_ERROR = "GET_GROUPS_ERROR";

export const SAVE_GROUPS = "SAVE_GROUPS";
export const SAVE_GROUPS_SUCCESS = "SAVE_GROUPS_SUCCESS";
export const SAVE_GROUPS_ERROR = "SAVE_GROUPS_ERROR";
export const RESET_SAVE_GROUPS = "RESET_SAVE_GROUPS";

export const GET_SINGLE_GROUP = "GET_SINGLE_GROUP";
export const GET_SINGLE_GROUP_SUCCESS = "GET_SINGLE_GROUP_SUCCESS";
export const GET_SINGLE_GROUP_ERROR = "GET_SINGLE_GROUP_ERROR";
export const RESET_GET_SINGLE_GROUP = "RESET_GET_SINGLE_GROUP";

export const UPDATE_SINGLE_GROUP = "UPDATE_SINGLE_GROUP";
export const UPDATE_SINGLE_GROUP_SUCCESS = "UPDATE_SINGLE_GROUP_SUCCESS";
export const UPDATE_SINGLE_GROUP_ERROR = "UPDATE_SINGLE_GROUP_ERROR";
export const RESET_UPDATE_SINGLE_GROUP = "RESET_UPDATE_SINGLE_GROUP";
