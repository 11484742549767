import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Navdata = () => {
  const history = useNavigate();
  //state data
  const [isManagement, setIsManagement] = useState(false);
  const [printManagement, setIsPrintManagment] = useState(false);

  const [iscurrentState, setIscurrentState] = useState("Management");

  function updateIconSidebar(e) {
    if (e && e.target && e.target.getAttribute("subitems")) {
      const ul = document.getElementById("two-column-menu");
      const iconItems = ul.querySelectorAll(".nav-icon.active");
      let activeIconItems = [...iconItems];
      activeIconItems.forEach((item) => {
        item.classList.remove("active");
        var id = item.getAttribute("subitems");
        if (document.getElementById(id))
          document.getElementById(id).classList.remove("show");
      });
    }
  }

  useEffect(() => {
    document.body.classList.remove("twocolumn-panel");
    if (iscurrentState !== "Management") {
      setIsManagement(false);
    }
  }, [history, iscurrentState]);

  const menuItems = [
    {
      label: "Menu",
      isHeader: true,
    },
    {
      id: "Dashboard",
      label: "Dashboard",
      icon: "ri-dashboard-2-line",
      link: "/dashboard",
      stateVariables: "",
      click: function (e) {
        e.preventDefault();
        setIsManagement(!isManagement);
        setIscurrentState("Management");
        //updateIconSidebar(e);
      },
    },
    {
      id: "Management",
      label: "Management",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: isManagement,
      click: function (e) {
        e.preventDefault();
        setIsManagement(!isManagement);
        setIscurrentState("Management");
        //updateIconSidebar(e);
      },
      subItems: [
        {
          id: "customers",
          label: "Customers",
          link: "/customers",
          parentId: "Management",
        },
        {
          id: "tariffs",
          label: "Tariffs",
          link: "/tariffs",
          parentId: "Management",
        },
        {
          id: "groups",
          label: "Groups",
          link: "/groups",
          parentId: "Management",
        },
      ],
    },
    {
      id: "Print",
      label: "Print Management",
      icon: "ri-dashboard-2-line",
      link: "/#",
      stateVariables: printManagement,
      click: function (e) {
        e.preventDefault();
        setIsPrintManagment(!printManagement);
        setIscurrentState("Print");
        //updateIconSidebar(e);
      },
      subItems: [
        {
          id: "print-sheet",
          label: "Print Sheet",
          link: "/print/print-sheet",
          parentId: "Print",
        },
      ],
    },
  ];
  return <React.Fragment>{menuItems}</React.Fragment>;
};
export default Navdata;
