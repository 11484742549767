import React, { useState } from "react";
import { Col, Container, Row, CardBody, Card, Button } from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import { greeting } from "../../helpers";
import { useNavigate } from "react-router-dom";

const Dashboard = () => {
  document.title = "Dashboard | ACF";
  const history = useNavigate();
  const navigationToPage = (url = "") => {
    url !== "" && history(url);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Dashboard" pageTitle="Home" />
          <Row className="justify-content-center">
            <Col xxl={12} className="mb-3">
              <div className="flex-grow-1">
                <h4 className="fs-16 mb-1">{`${greeting()}`}, Anna!</h4>
                <p className="text-muted mb-0">
                  Here's what's happening with your store today.
                </p>
              </div>
            </Col>
            <Col>
              <Card>
                <CardBody className="bg-dark-subtle border-opacity-10">
                  <Row>
                    <Col>
                      <Card>
                        <CardBody>
                          <h4 className="mb-3">Manage Customers</h4>
                          <p className="card-text text-muted">
                            A comprehensive module for customers to read,
                            create, update, and perform various operations.
                          </p>
                          <Button
                            color="primary"
                            className="w-100"
                            onClick={() => navigationToPage("/customers")}
                          >
                            See Details
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <CardBody>
                          <h4 className="mb-3">Manage Tariffs</h4>
                          <p className="card-text text-muted">
                            A comprehensive module for managing tariffs,
                            including reading, creating, updating, and
                            performing various operations.
                          </p>
                          <Button
                            color="primary"
                            className="w-100"
                            onClick={() => navigationToPage("/tariffs")}
                          >
                            See Details
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col>
                      <Card>
                        <CardBody>
                          <h4 className="mb-3">Manage Groups</h4>
                          <p className="card-text text-muted">
                            A comprehensive module to manage groups, allowing
                            you to read, create, update, and perform various
                            operations.
                          </p>
                          <Button
                            color="primary"
                            className="w-100"
                            onClick={() => navigationToPage("/groups")}
                          >
                            See Details
                          </Button>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
