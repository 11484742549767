import {
  GET_GROUPS,
  GET_GROUPS_SUCCESS,
  GET_GROUPS_ERROR,
  SAVE_GROUPS,
  SAVE_GROUPS_SUCCESS,
  SAVE_GROUPS_ERROR,
  RESET_SAVE_GROUPS,
  GET_SINGLE_GROUP,
  GET_SINGLE_GROUP_SUCCESS,
  GET_SINGLE_GROUP_ERROR,
  RESET_GET_SINGLE_GROUP,
  UPDATE_SINGLE_GROUP,
  UPDATE_SINGLE_GROUP_SUCCESS,
  UPDATE_SINGLE_GROUP_ERROR,
  RESET_UPDATE_SINGLE_GROUP,
} from "./actionType";

export const getGroups = () => {
  return {
    type: GET_GROUPS,
  };
};

export const groupSuccess = (list) => {
  return {
    type: GET_GROUPS_SUCCESS,
    payload: list,
  };
};

export const groupListingError = () => {
  return {
    type: GET_GROUPS_ERROR,
  };
};

export const saveGroup = (payload) => {
  return {
    type: SAVE_GROUPS,
    payload,
  };
};

export const saveGroupSucces = (payload) => {
  return {
    type: SAVE_GROUPS_SUCCESS,
    payload,
  };
};

export const saveGroupError = (payload) => {
  return {
    type: SAVE_GROUPS_ERROR,
    payload,
  };
};

export const resetGroupSavedData = () => {
  return {
    type: RESET_SAVE_GROUPS,
  };
};

export const getSingleGroup = (payload) => {
  return {
    type: GET_SINGLE_GROUP,
    payload,
  };
};

export const getSingleGroupSucces = (payload) => {
  return {
    type: GET_SINGLE_GROUP_SUCCESS,
    payload,
  };
};

export const getSingleGroupError = (payload) => {
  return {
    type: GET_SINGLE_GROUP_ERROR,
    payload,
  };
};

export const resetSingleGroupSavedData = () => {
  return {
    type: RESET_GET_SINGLE_GROUP,
  };
};

export const updateGroups = (payload, id) => {
  return {
    type: UPDATE_SINGLE_GROUP,
    payload,
    id,
  };
};

export const updateGroupsSucces = (payload) => {
  return {
    type: UPDATE_SINGLE_GROUP_SUCCESS,
    payload,
  };
};

export const updateGroupsError = (payload) => {
  return {
    type: UPDATE_SINGLE_GROUP_ERROR,
    payload,
  };
};

export const resetUpdateGroupsData = () => {
  return {
    type: RESET_UPDATE_SINGLE_GROUP,
  };
};
