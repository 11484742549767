import { combineReducers } from "redux";
import Layout from "./layouts/reducer";
import Login from "./auth/login/reducer";
import Customers from "./customers/reducer";
import Tariffs from "./tariffs/reducer";
import Groups from "./groups/reducer";
import Print from "./prints/reducer";
const rootReducer = combineReducers({
  Layout,
  Login,
  Customers,
  Tariffs,
  Groups,
  Print,
});

export default rootReducer;
