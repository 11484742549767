import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  SAVE_CUSTOMER,
  SAVE_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER_ERROR,
  RESET_SAVE_CUSTOMER,
  GET_SINGLE_CUSTOMER,
  GET_SINGLE_CUSTOMER_SUCCESS,
  GET_SINGLE_CUSTOMER_ERROR,
  RESET_GET_SINGLE_CUSTOMER,
  UPDATE_SINGLE_CUSTOMER,
  UPDATE_SINGLE_CUSTOMER_SUCCESS,
  UPDATE_SINGLE_CUSTOMER_ERROR,
  RESET_UPDATE_SINGLE_CUSTOMER,
} from "./actionType";

export const getCustomers = () => {
  return {
    type: GET_CUSTOMERS,
  };
};

export const customerSuccess = (list) => {
  return {
    type: GET_CUSTOMERS_SUCCESS,
    payload: list,
  };
};

export const customerListingError = () => {
  return {
    type: GET_CUSTOMERS_ERROR,
  };
};

export const saveCustomer = (payload) => {
  return {
    type: SAVE_CUSTOMER,
    payload,
  };
};

export const saveCustomerSucces = (payload) => {
  return {
    type: SAVE_CUSTOMER_SUCCESS,
    payload,
  };
};

export const saveCustomerError = (payload) => {
  return {
    type: SAVE_CUSTOMER_ERROR,
    payload,
  };
};

export const resetCustomerSavedData = () => {
  return {
    type: RESET_SAVE_CUSTOMER,
  };
};

export const getSingleCustomer = (payload) => {
  return {
    type: GET_SINGLE_CUSTOMER,
    payload,
  };
};

export const getSingleCustomerSucces = (payload) => {
  return {
    type: GET_SINGLE_CUSTOMER_SUCCESS,
    payload,
  };
};

export const getSingleCustomerError = (payload) => {
  return {
    type: GET_SINGLE_CUSTOMER_ERROR,
    payload,
  };
};

export const resetSingleCustomerSavedData = () => {
  return {
    type: RESET_GET_SINGLE_CUSTOMER,
  };
};

export const updateCustomer = (payload, id) => {
  return {
    type: UPDATE_SINGLE_CUSTOMER,
    payload,
    id,
  };
};

export const updateCustomerSucces = (payload) => {
  return {
    type: UPDATE_SINGLE_CUSTOMER_SUCCESS,
    payload,
  };
};

export const updateCustomerError = (payload) => {
  return {
    type: UPDATE_SINGLE_CUSTOMER_ERROR,
    payload,
  };
};

export const resetUpdateCustomerData = () => {
  return {
    type: RESET_UPDATE_SINGLE_CUSTOMER,
  };
};
