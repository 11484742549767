import React, { useMemo, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import {
  Col,
  Container,
  Row,
  CardBody,
  Card,
  Button,
  Progress,
  ButtonGroup,
  UncontrolledButtonDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Grid from "../../Components/Grid/Grid";
import { getGroups } from "../../store/actions";
import { useNavigate, useLocation } from "react-router-dom";
import { createSelector } from "reselect";
import { useSelector, useDispatch } from "react-redux";
import { formatIndianRupee } from "../../helpers";

const Groups = () => {
  document.title = "Groups | Arun Chit Funds, Oddanchatram";
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const [doRefresh, setDoRefresh] = useState(false);
  const selectGroupsListState = (state) => state.Groups;
  const selectGroupsProperties = createSelector(
    selectGroupsListState,
    (groups) => ({
      gridData: groups?.data,
      loading: groups?.loading,
      error: groups?.error,
      success: groups?.success,
    })
  );
  const { gridData, loading, error, success } = useSelector(
    selectGroupsProperties
  );
  const gridColumns = useMemo(
    () => [
      {
        data: "id",
        title: "Group ID",
      },
      {
        data: "name",
        title: "Group Name",
      },
      {
        data: "started_month",
        title: "Started Month",
      },
      {
        data: "chit_amount",
        title: "Chit Amount",
      },
      {
        data: "id",
        title: "Pending Allocation",
      },
      {
        data: "tarrifs_months",
        title: "Tariff Months",
      },
      {
        data: "id",
        title: "Action",
      },
    ],
    []
  );
  const buttons = (
    <>
      <Button
        color="primary"
        className="bg-gradient"
        disabled={loading}
        onClick={() => {
          history("/groups/entry-form", { state: { mode: "NEW" } });
        }}
      >
        Create Group
      </Button>
      <Button
        color="primary"
        className="bg-gradient ms-2"
        disabled={loading}
        onClick={() => setDoRefresh(true)}
      >
        Refresh
      </Button>
    </>
  );

  const columnDefs = [
    {
      targets: [3],
      createdCell: (td, cellData, rowData, row, col) =>
        ReactDOM.render(
          <>
            <span
              dangerouslySetInnerHTML={{ __html: formatIndianRupee(cellData) }}
            ></span>
          </>,
          td
        ),
    },
    {
      targets: [4],
      createdCell: (td, cellData, rowData, row, col) => {
        const count = rowData.customers.reduce((a, b) => {
          return a + b.count;
        }, 0);
        return ReactDOM.render(
          <>
            <Progress
              value={75}
              striped
              animated
              className="progress-xl"
            >{`${count} out of ${rowData?.max_allowed_members}`}</Progress>
          </>,
          td
        );
      },
    },
    {
      targets: [6],
      createdCell: (td, cellData, rowData, row, col) => {
        const count = rowData.customers.reduce((a, b) => {
          return a + b.count;
        }, 0);
        return ReactDOM.render(
          <>
            <ButtonGroup>
              <UncontrolledButtonDropdown id="btnGroupDrop1">
                <DropdownToggle
                  color="primary"
                  outline
                  caret
                  className="btn-soft-primary"
                >
                  Action
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem onClick={() => viewOnClick(cellData)}>
                    View
                  </DropdownItem>
                  <DropdownItem onClick={() => editOnClick(cellData)}>
                    Edit
                  </DropdownItem>
                  <DropdownItem>Customers</DropdownItem>
                  <DropdownItem>Tariff Info</DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </ButtonGroup>
          </>,
          td
        );
      },
    },
  ];

  const editOnClick = (editID) => {
    history("/groups/entry-form", { state: { mode: "EDIT", editID } });
  };

  const viewOnClick = (editID) => {
    history("/groups/entry-form", { state: { mode: "VIEW", editID } });
  };

  useEffect(() => {
    location?.state !== null &&
      location?.state?.refresh &&
      setDoRefresh(location?.state?.refresh);
  }, [location.state]);

  useEffect(() => {
    !success && !doRefresh && dispatch(getGroups());
  }, [success]);

  useEffect(() => {
    if (doRefresh) {
      dispatch(getGroups());
      setDoRefresh(false);
    }
  }, [doRefresh]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Groups" pageTitle="Home" />
          <Row className="justify-content-center">
            <Col>
              <Card>
                <CardBody>
                  <Grid
                    columns={gridColumns}
                    data={gridData || []}
                    buttons={buttons}
                    isLoading={loading}
                    columnDefs={columnDefs}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Groups;
