import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, CardBody, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { getPrintSheet, getPrintSheetReset } from "../../store/actions";
import { createSelector } from "reselect";
import { formatIndianRupee, groupBy } from "../../helpers";
import "./print.scss";

const PrintSheet = () => {
  document.title = "Print | ACF";
  const dispatch = useDispatch();
  const [printData, setPrintData] = useState([]);
  const selectPrintSheetState = (state) => state.Print;
  const selectPrintProperties = createSelector(
    selectPrintSheetState,
    (print) => ({
      gridData: print?.data,
      loading: print?.loading,
      error: print?.error,
      success: print?.success,
    })
  );
  const {
    gridData = [],
    loading,
    error,
    success,
  } = useSelector(selectPrintProperties);

  const printMe = () => {
    window.print();
  };
  useEffect(() => {
    !success && dispatch(getPrintSheet());
  }, [success]);

  const replaceDuplicateNameWithComma = (arr = []) => {
    return arr.map((obj) => {
      return Array.from({ length: obj.count }, (o, i) =>
        i == 0 ? obj.name : `"   "`
      );
    });
  };

  useEffect(() => {
    setPrintData(groupBy(gridData, "chit_amount"));
  }, [gridData]);

  useEffect(() => {
    dispatch(getPrintSheetReset());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className="justify-content-center">
            <Col>
              <Card>
                {loading ? (
                  <CardBody>
                    <h5 className="card-title placeholder-glow">
                      <span className="placeholder col-6"></span>
                    </h5>
                    <p className="card-text placeholder-glow">
                      <span className="placeholder col-7"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-4"></span>
                      <span className="placeholder col-6"></span>
                    </p>
                  </CardBody>
                ) : (
                  <CardBody>
                    <Row className="mb-3 dont-print-me">
                      <Col xxl={12}>
                        <Button color="success" onClick={printMe}>
                          <i className="ri-printer-line align-bottom me-1"></i>{" "}
                          Print
                        </Button>
                      </Col>
                    </Row>
                    <Row className="row-cols-5">
                      {Object.entries(printData).map(([key, dataToDisplay]) =>
                        dataToDisplay.map(
                          (
                            {
                              id,
                              endOfMonthChit,
                              currentMonthChitAmount,
                              currentChitMonth,
                              customers,
                              chit_amount,
                              tarrifs_id,
                              started_month,
                              tarrifs_months,
                            },
                            i
                          ) => (
                            <Col
                              className="border border-dark g-0"
                              key={`${key}-${i}`}
                            >
                              <div className="print-block-content">
                                <div className="content-section">
                                  <ol className="m-0 py-2">
                                    {replaceDuplicateNameWithComma(
                                      customers
                                    ).map((names, index) =>
                                      names.map((name) => (
                                        <>
                                          <li
                                            key={`name-${index}`}
                                          >{`${name}`}</li>
                                        </>
                                      ))
                                    )}
                                  </ol>
                                  <div className="verticalText text-muted">
                                    {`${formatIndianRupee(
                                      chit_amount
                                    )}/T${tarrifs_id}/${started_month}`}
                                  </div>
                                </div>
                                <div className="border-top border-dark p-0 m-0 fw-bold text-center print-bottom-footer">{`Due ${currentChitMonth},${formatIndianRupee(
                                  currentMonthChitAmount
                                )},${endOfMonthChit},G${id}`}</div>
                              </div>
                            </Col>
                          )
                        )
                      )}
                    </Row>
                  </CardBody>
                )}
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PrintSheet;
