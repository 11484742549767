import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { APIClient } from "../../helpers/api_helper";

import { GET_PRINTSHEET } from "./actionType";
import { getPrintSheetSuccess } from "./action";
import { apiError } from "../auth/login/actions";

const api = new APIClient();

function* PrintsApi() {
  try {
    const getPrints = (data) => api.get("/print/sheet", data, true);
    const response = yield call(getPrints);
    yield put(getPrintSheetSuccess(response));
  } catch (error) {
    yield put(apiError(error));
  }
}

function* PrintSaga() {
  yield takeEvery(GET_PRINTSHEET, PrintsApi);
}

export default PrintSaga;
