import React from "react";
import { useIdleTimer } from "react-idle-timer";
import "./assets/scss/themes.scss";
import Route from "./Routes";
import { useNavigate } from "react-router-dom";

function App() {
  const history = useNavigate();
  const onIdle = () => {
    history("/logout");
  };
  const { getRemainingTime } = useIdleTimer({
    onIdle,
    timeout: 10 * 60 * 1000,
  });
  return (
    <React.Fragment>
      <Route />
    </React.Fragment>
  );
}

export default App;
