export const GET_CUSTOMERS = "GET_CUSTOMERS";
export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS";
export const GET_CUSTOMERS_ERROR = "GET_CUSTOMERS_ERROR";

export const SAVE_CUSTOMER = "SAVE_CUSTOMER";
export const SAVE_CUSTOMER_SUCCESS = "SAVE_CUSTOMER_SUCCESS";
export const SAVE_CUSTOMER_ERROR = "SAVE_CUSTOMER_ERROR";
export const RESET_SAVE_CUSTOMER = "RESET_SAVE_CUSTOMER";

export const GET_SINGLE_CUSTOMER = "GET_SINGLE_CUSTOMER";
export const GET_SINGLE_CUSTOMER_SUCCESS = "GET_SINGLE_CUSTOMER_SUCCESS";
export const GET_SINGLE_CUSTOMER_ERROR = "GET_SINGLE_CUSTOMER_ERROR";
export const RESET_GET_SINGLE_CUSTOMER = "RESET_GET_SINGLE_CUSTOMER";

export const UPDATE_SINGLE_CUSTOMER = "UPDATE_SINGLE_CUSTOMER";
export const UPDATE_SINGLE_CUSTOMER_SUCCESS = "UPDATE_SINGLE_CUSTOMER_SUCCESS";
export const UPDATE_SINGLE_CUSTOMER_ERROR = "UPDATE_SINGLE_CUSTOMER_ERROR";
export const RESET_UPDATE_SINGLE_CUSTOMER = "RESET_UPDATE_SINGLE_CUSTOMER";
