import { all, fork } from "redux-saga/effects";
import LayoutSaga from "./layouts/saga";
import AuthSaga from "./auth/login/saga";
import dashboard from "./dashboard/saga";
import CustomersSaga from "./customers/saga";
import TariffsSaga from "./tariffs/saga";
import GroupsSaga from "./groups/saga";
import PrintSaga from "./prints/saga";

export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AuthSaga),
    fork(dashboard),
    fork(CustomersSaga),
    fork(TariffsSaga),
    fork(GroupsSaga),
    fork(PrintSaga),
  ]);
}
