import React, { useState, useMemo, useEffect } from "react";
import ReactDOM from "react-dom";
import { Col, Container, Row, CardBody, Card, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import BreadCrumb from "../../Components/Common/BreadCrumb";
import Grid from "../../Components/Grid/Grid";
import { getTariffs, resetUpdateTariffData } from "../../store/actions";
import { createSelector } from "reselect";
import { useNavigate, useLocation } from "react-router-dom";
import { formatIndianRupee } from "../../helpers";
import ViewTariff from "./view";

const Tariffs = () => {
  document.title = "Tariffs Management | Arun Chit Funds, Oddanchatram";
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const [doRefresh, setDoRefresh] = useState(false);
  const [openViewModal, setOpenViewModal] = useState(false);
  const [dataToView, setDataDataToView] = useState({});
  const selectTariffsListState = (state) => state.Tariffs;
  const selectTariffssProperties = createSelector(
    selectTariffsListState,
    (tariffs) => ({
      gridData: tariffs?.data,
      loading: tariffs?.loading,
      error: tariffs?.error,
      success: tariffs?.success,
    })
  );
  const { gridData, loading, error, success } = useSelector(
    selectTariffssProperties
  );
  const gridColumns = useMemo(
    () => [
      {
        data: "id",
        title: "Tariff ID",
      },
      {
        data: "name",
        title: "Tariff Name",
      },
      {
        data: "months",
        title: "Tariff Months",
      },
      {
        data: "chit_amount",
        title: "Chit Amount",
      },
      {
        data: "id",
        title: "Action",
      },
    ],
    []
  );

  const addBtnOnClick = () => {
    history("/tariffs/entry-form", { state: { mode: "NEW" } });
  };

  const buttons = (
    <>
      <Button
        color="primary"
        className="bg-gradient"
        disabled={false}
        onClick={() => {
          addBtnOnClick();
        }}
      >
        Add New Tariff
      </Button>
      <Button
        color="primary"
        className="bg-gradient ms-2"
        disabled={false}
        onClick={() => setDoRefresh(true)}
      >
        Refresh
      </Button>
    </>
  );

  const columnDefs = [
    {
      targets: [3],
      createdCell: (td, cellData, rowData, row, col) =>
        ReactDOM.render(
          <>
            <span
              dangerouslySetInnerHTML={{ __html: formatIndianRupee(cellData) }}
            ></span>
          </>,
          td
        ),
    },
    {
      targets: [4],
      createdCell: (td, cellData, rowData, row, col) =>
        ReactDOM.render(
          <>
            <Button
              color="primary"
              className="bg-gradient btn-sm me-2"
              onClick={() => {
                editOnClick(cellData);
              }}
            >
              {" "}
              Edit{" "}
            </Button>
            <Button
              color="primary"
              className="bg-gradient btn-sm"
              onClick={() => {
                viewOnClick(rowData);
              }}
            >
              {" "}
              View{" "}
            </Button>
          </>,
          td
        ),
    },
  ];

  const editOnClick = (editID) => {
    history("/tariffs/entry-form", { state: { mode: "EDIT", editID } });
  };

  const viewOnClick = (rowData) => {
    setDataDataToView(rowData);
    setOpenViewModal(true);
  };

  const onViewModalClose = () => {
    setOpenViewModal(false);
    setDataDataToView({});
  };

  useEffect(() => {
    !success && !doRefresh && dispatch(getTariffs());
  }, [success]);

  useEffect(() => {
    if (doRefresh) {
      dispatch(getTariffs());
      setDoRefresh(false);
    }
  }, [doRefresh]);

  useEffect(() => {
    location?.state !== null &&
      location?.state?.refresh &&
      setDoRefresh(location?.state?.refresh);
  }, [location.state]);

  useEffect(() => {
    dispatch(resetUpdateTariffData());
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Tariffs Management" pageTitle="Dashboard" />
          <Row className="justify-content-center">
            <Col>
              <Card>
                <CardBody>
                  <Grid
                    columns={gridColumns}
                    data={gridData || []}
                    isLoading={loading}
                    buttons={buttons}
                    columnDefs={columnDefs}
                  />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
        {openViewModal && (
          <ViewTariff
            onViewModalClose={onViewModalClose}
            dataToView={dataToView}
          />
        )}
      </div>
    </React.Fragment>
  );
};

export default Tariffs;
