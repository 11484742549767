import {
  GET_CUSTOMERS,
  GET_CUSTOMERS_SUCCESS,
  GET_CUSTOMERS_ERROR,
  SAVE_CUSTOMER,
  SAVE_CUSTOMER_SUCCESS,
  SAVE_CUSTOMER_ERROR,
  RESET_SAVE_CUSTOMER,
  GET_SINGLE_CUSTOMER,
  GET_SINGLE_CUSTOMER_SUCCESS,
  GET_SINGLE_CUSTOMER_ERROR,
  RESET_GET_SINGLE_CUSTOMER,
  UPDATE_SINGLE_CUSTOMER,
  UPDATE_SINGLE_CUSTOMER_SUCCESS,
  UPDATE_SINGLE_CUSTOMER_ERROR,
  RESET_UPDATE_SINGLE_CUSTOMER,
} from "./actionType";

const INIT_STATE = {
  data: [],
  loading: false,
  error: false,
  success: false,
  saveNewCustomer: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  singleCustomer: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
  updateCustomer: {
    data: [],
    loading: false,
    error: false,
    success: false,
  },
};

const Customers = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_CUSTOMERS:
      state = {
        ...state,
        data: [],
        loading: true,
        error: false,
        success: false,
      };
      break;
    case GET_CUSTOMERS_SUCCESS:
      state = {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        success: true,
      };
      break;
    case GET_CUSTOMERS_ERROR:
      state = {
        ...state,
        data: [],
        loading: false,
        error: true,
        success: false,
      };
      break;
    case SAVE_CUSTOMER:
      state = {
        ...state,
        saveNewCustomer: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case SAVE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        saveNewCustomer: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case SAVE_CUSTOMER_ERROR:
      state = {
        ...state,
        saveNewCustomer: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_SAVE_CUSTOMER:
      state = {
        ...state,
        saveNewCustomer: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    case GET_SINGLE_CUSTOMER:
      state = {
        ...state,
        singleCustomer: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case GET_SINGLE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        singleCustomer: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case GET_SINGLE_CUSTOMER_ERROR:
      state = {
        ...state,
        singleCustomer: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_GET_SINGLE_CUSTOMER:
      state = {
        ...state,
        singleCustomer: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    case UPDATE_SINGLE_CUSTOMER:
      state = {
        ...state,
        updateCustomer: {
          data: [],
          loading: true,
          error: false,
          success: false,
        },
      };
      break;
    case UPDATE_SINGLE_CUSTOMER_SUCCESS:
      state = {
        ...state,
        updateCustomer: {
          data: action.payload,
          loading: false,
          error: false,
          success: true,
        },
      };
      break;
    case UPDATE_SINGLE_CUSTOMER_ERROR:
      state = {
        ...state,
        updateCustomer: {
          data: action.payload,
          loading: false,
          error: true,
          success: false,
        },
      };
      break;
    case RESET_UPDATE_SINGLE_CUSTOMER:
      state = {
        ...state,
        updateCustomer: {
          data: [],
          loading: false,
          error: false,
          success: false,
        },
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Customers;
