import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Input,
  Button,
  Form,
  FormFeedback,
  Spinner,
  Alert,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  saveCustomer,
  resetCustomerSavedData,
  getSingleCustomer,
  updateCustomer,
  resetUpdateCustomerData,
} from "../../store/actions";
import { createSelector } from "reselect";
import Loader from "../../Components/Common/Loader";

const EntryForm = ({
  isOpen = false,
  onModalToggleCB,
  mode = "NEW",
  idToEdit = 0,
}) => {
  const [modal_grid, setmodal_grid] = useState(isOpen);
  const [preventRedirect, setPreventRedirect] = useState(false);
  const dispatch = useDispatch();
  const selectCustomersListState = (state) => state.Customers;
  const selectCustomersProperties = createSelector(
    selectCustomersListState,
    (customers) => ({
      savedCustomerData: customers?.saveNewCustomer?.data,
      loading: customers?.saveNewCustomer?.loading,
      error: customers?.saveNewCustomer?.error,
      success: customers?.saveNewCustomer?.success,
      singleCustomerData: customers?.singleCustomer?.data,
      singleCustomerLoading: customers?.singleCustomer?.loading,
      singleCustomerError: customers?.singleCustomer?.error,
      singleCustomerSuccess: customers?.singleCustomer?.success,
      updateCustomerData: customers?.updateCustomer?.data,
      updateCustomerLoading: customers?.updateCustomer?.loading,
      updateCustomerError: customers?.updateCustomer?.error,
      updateCustomerSuccess: customers?.updateCustomer?.success,
    })
  );
  const {
    savedCustomerData,
    loading,
    error,
    success,
    singleCustomerData,
    singleCustomerSuccess,
    singleCustomerLoading,
    updateCustomerData,
    updateCustomerSuccess,
    updateCustomerLoading,
    updateCustomerError,
  } = useSelector(selectCustomersProperties);
  const tog_grid = () => {
    setmodal_grid(!modal_grid);
  };
  const formik = useFormik({
    enableReinitialize: true,
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: {
      name: mode == "EDIT" ? singleCustomerData[0]?.name : "",
      phone: mode == "EDIT" ? singleCustomerData[0]?.phone : "",
      address: mode == "EDIT" ? singleCustomerData[0]?.address : "",
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .required("Name is required")
        .min(2, "Name must be at least 2 characters")
        .max(50, "Name can be at most 50 characters")
        .matches(
          /^[a-zA-Z0-9()-\s]*$/,
          "Name can only contain letters and spaces"
        ),
      phone: Yup.string()
        .matches(/^[6-9]\d{9}$/, "Invalid Indian phone number")
        .required("Phone number is required"),
    }),
    onSubmit: (values) => {
      const additionalInfo = { addedBy: 1 };
      mode === "NEW"
        ? dispatch(saveCustomer({ ...values, ...additionalInfo }))
        : dispatch(updateCustomer({ ...values, ...additionalInfo }, idToEdit));
    },
  });
  useEffect(() => {
    onModalToggleCB(modal_grid);
    setPreventRedirect(true);
    dispatch(resetCustomerSavedData());
    dispatch(resetUpdateCustomerData());
  }, [modal_grid]);

  useEffect(() => {
    preventRedirect &&
      (success || updateCustomerSuccess) &&
      setTimeout(function () {
        onModalToggleCB(false, true);
      }, 3000);
  }, [success, updateCustomerSuccess]);

  useEffect(() => {
    idToEdit > 0 && mode !== "NEW" && dispatch(getSingleCustomer(idToEdit));
  }, [idToEdit, mode]);

  return (
    <Modal
      isOpen={true}
      toggle={() => {
        tog_grid();
      }}
    >
      <ModalHeader>
        <h5 className="modal-title">Create New Customer Profile</h5>
      </ModalHeader>
      <ModalBody>
        {singleCustomerLoading && <Loader />}
        {error && (
          <Alert color="danger">
            {`${savedCustomerData?.error_message?.toString()}`}
          </Alert>
        )}
        {success && (
          <Alert color="success">{`${savedCustomerData?.message}`}</Alert>
        )}
        {updateCustomerSuccess && (
          <Alert color="success">{`${updateCustomerData?.message}`}</Alert>
        )}
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
          action="#"
        >
          <div className="row g-3">
            <Col xxl={6}>
              <div>
                <label htmlFor="name" className="form-label">
                  Name <span className="text-danger">*</span>
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  placeholder="Enter Customer Name"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                />
                {formik.touched.name && formik.errors.name ? (
                  <FormFeedback type="invalid">
                    {formik.errors.name}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xxl={6}>
              <div>
                <label htmlFor="lastName" className="form-label">
                  Phone <span className="text-danger">*</span>
                </label>
                <Input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  placeholder="Enter Customer Phone"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  invalid={formik.touched.phone && formik.errors.phone}
                  value={formik.values.phone}
                />
                {formik.touched.phone && formik.errors.phone ? (
                  <FormFeedback type="invalid">
                    {formik.errors.phone}
                  </FormFeedback>
                ) : null}
              </div>
            </Col>
            <Col xxl={6}>
              <label htmlFor="address" className="form-label">
                Address
              </label>
              <Input
                type="text"
                className="form-control"
                id="address"
                name="address"
                placeholder="Enter Customer Address"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                invalid={formik.touched.address && formik.errors.address}
                value={formik.values.address}
              />
              {formik.touched.address && formik.errors.address ? (
                <FormFeedback type="invalid">
                  {formik.errors.address}
                </FormFeedback>
              ) : null}
            </Col>
            <div className="col-lg-12">
              <div className="hstack gap-2 justify-content-end">
                <Button color="light" onClick={() => setmodal_grid(false)}>
                  Close
                </Button>
                {mode === "EDIT" && (
                  <Button color="primary" type="submit">
                    Update
                    {updateCustomerError ? null : updateCustomerLoading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                  </Button>
                )}
                {mode === "NEW" && (
                  <Button color="primary" type="submit">
                    Submit
                    {error ? null : loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                  </Button>
                )}
              </div>
            </div>
          </div>
        </Form>
      </ModalBody>
    </Modal>
  );
};

export default EntryForm;
