import {
  GET_PRINTSHEET,
  GET_PRINTSHEET_SUCCESS,
  GET_PRINTSHEET_ERROR,
  GET_PRINTSHEET_RESET,
} from "./actionType";

export const getPrintSheet = () => {
  return {
    type: GET_PRINTSHEET,
  };
};

export const getPrintSheetSuccess = (list) => {
  return {
    type: GET_PRINTSHEET_SUCCESS,
    payload: list,
  };
};

export const getPrintSheetError = () => {
  return {
    type: GET_PRINTSHEET_ERROR,
  };
};

export const getPrintSheetReset = () => {
  return {
    type: GET_PRINTSHEET_RESET,
  };
};
