export const GET_TARIFFS = "GET_TARIFFS";
export const GET_TARIFFS_SUCCESS = "GET_TARIFFS_SUCCESS";
export const GET_TARIFFS_ERROR = "GET_TARIFFS_ERROR";

export const SAVE_TARIFF = "SAVE_TARIFF";
export const SAVE_TARIFF_SUCCESS = "SAVE_TARIFF_SUCCESS";
export const SAVE_TARIFF_ERROR = "SAVE_TARIFF_ERROR";
export const RESET_SAVE_TARIFF = "RESET_SAVE_TARIFF";

export const GET_SINGLE_TARIFF = "GET_SINGLE_TARIFF";
export const GET_SINGLE_TARIFF_SUCCESS = "GET_SINGLE_TARIFF_SUCCESS";
export const GET_SINGLE_TARIFF_ERROR = "GET_SINGLE_TARIFF_ERROR";
export const RESET_GET_SINGLE_TARIFF = "RESET_GET_SINGLE_TARIFF";

export const UPDATE_SINGLE_TARIFF = "UPDATE_SINGLE_TARIFF";
export const UPDATE_SINGLE_TARIFF_SUCCESS = "UPDATE_SINGLE_TARIFF_SUCCESS";
export const UPDATE_SINGLE_TARIFF_ERROR = "UPDATE_SINGLE_TARIFF_ERROR";
export const RESET_UPDATE_SINGLE_TARIFF = "RESET_UPDATE_SINGLE_TARIFF";
