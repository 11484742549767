import React, { useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import DataTable from "datatables.net-dt";
import $ from "jquery";
import "./grid.scss";

const Grid = ({
  columns,
  data,
  id,
  sortOrder = [0, "asc"],
  pageLength = 50,
  isLoading = false,
  ordering = true,
  columnDefs,
  buttons = "",
}) => {
  const tableGridRef = useRef();
  useEffect(() => {
    const table = $(tableGridRef.current).DataTable({
      order: [sortOrder],
      columns,
      data,
      ordering,
      columnDefs,
      pageLength: pageLength,
      processing: isLoading,
      deferRender: true,
      language: {
        search: "<i></i>",
        searchPlaceholder: "Type a keyword...",
      },
      initComplete: () => {
        if (buttons !== "") {
          const $chatDiv = $("<div class='topBar'>");
          $("div.dataTables_filter").append($chatDiv);
          ReactDOM.render(buttons, $chatDiv[0]);
        }
      },
    });
    return () => table.destroy();
  }, [isLoading]);
  return (
    <>
      {isLoading ? (
        <>
          <h5 className="card-title placeholder-glow">
            <span className="placeholder col-6"></span>
          </h5>
          <p className="card-text placeholder-glow">
            <span className="placeholder col-7"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-4"></span>
            <span className="placeholder col-6"></span>
          </p>
        </>
      ) : (
        <div className="table-responsive dataTable_table_wrapper pb-2">
          <table
            id={id}
            ref={tableGridRef}
            className="table table-bordered table-hover"
          ></table>
        </div>
      )}
    </>
  );
};

export default Grid;
